import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import useEventListener from '@core/hooks/useEventListener';
import makeFetch from '@core/utils/makeFetch';

import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import { isRecipe } from '../blocks/Recipe/shared';
import MenuDropdown from '../MenuDropdown';
import menuClasses from '../style.module.scss';

import { sampleRecipes } from './sampleRecipes';

const RecipeMenu = ({ subdomain, useAPIv2, version, state: [node, { close }] }) => {
  const editor = useSlateStatic();
  const getElement = useCallback(() => {
    if (!node) return;

    try {
      // eslint-disable-next-line consistent-return
      return ReactEditor.toDOMNode(editor, node);
    } catch (e) {
      // @note: https://owlbert.io/images/gifs/fine.gif
      //
      // ReactEditor.toDOMNode throws an error when the node is not found, but
      // it's a really fast lookup, as slate keeps a map of node -> element.
      //
      // But also, we trigger an open synchronously, before the element has
      // rendered.
    }
  }, [editor, node]);
  const target = { current: getElement() };

  const path = node && ReactEditor.findPath(editor, node);
  const open = !!node;

  const removeRecipe = useCallback(() => {
    Transforms.delete(editor, { at: path });
    close();
  }, [close, editor, path]);

  useEffect(() => {
    if (!node || !isRecipe(node)) {
      close();
    }
  }, [node, close]);

  // Close on esc key
  useEventListener(
    'keydown',
    useCallback(event => open && event.key === 'Escape' && close(), [close, open]),
  );

  const [recipes, setRecipes] = useState();
  const isMounted = useRef(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editor.props.useTestData || typeof fetch === 'undefined') {
      setRecipes(sampleRecipes);
    } else {
      const url = useAPIv2
        ? `/${subdomain}/api-next/v2/versions/${version}/recipes`
        : `/api/projects/${subdomain}/v${version}/recipes`;

      makeFetch(url, {
        method: 'GET',
      }).then(async data => {
        const results = await data.json();
        if (isMounted.current) setRecipes(useAPIv2 ? results.data : results);
      });

      return () => {
        isMounted.current = false;
      };
    }
  }, [editor.props.useTestData, subdomain, useAPIv2, version]);

  const selectedRecipe = useMemo(
    () => (node?.id && recipes ? recipes.find(recipe => recipe.id === node.id) : null),
    [node?.id, recipes],
  );

  const selectRecipe = useCallback(
    event => {
      const id = event.target.dataset.id;

      Transforms.setNodes(
        editor,
        recipes.find(recipe => recipe.id === id),
        { at: path },
      );

      close();
    },
    [close, editor, path, recipes],
  );

  return (
    <MenuDropdown close={close} data-testid="recipe-menu" open={open} overlay target={target}>
      <Menu role="menu">
        {recipes?.length ? (
          <div className={menuClasses['EditorMenu-list']}>
            <MenuHeader>Choose Recipe</MenuHeader>
            {recipes.map(recipe => (
              <MenuItem
                key={recipe.id}
                active={selectedRecipe?.title === recipe.title}
                data-id={recipe.id}
                data-testid={`recipe-${recipe.id}`}
                onClick={selectRecipe}
              >
                <>
                  {recipe.emoji} {recipe.title}
                </>
              </MenuItem>
            ))}
          </div>
        ) : (
          <MenuItem
            color="blue"
            href={`/go/${subdomain}?redirect=/v${version}/recipes`}
            rel="noreferrer"
            TagName="a"
            target="_blank"
          >
            Set Up Recipes
          </MenuItem>
        )}
        <MenuDivider />
        <MenuItem color="red" icon="icon icon-trash1" onClick={removeRecipe}>
          Delete
        </MenuItem>
      </Menu>
    </MenuDropdown>
  );
};

RecipeMenu.propTypes = {
  close: PropTypes.func,
  node: PropTypes.object,
  state: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({
        close: PropTypes.func,
        open: PropTypes.func,
      }),
    ]),
  ).isRequired,
  subdomain: PropTypes.string,
  useAPIv2: PropTypes.bool,
  version: PropTypes.string,
};

export { default as useRecipeMenu } from './useRecipeMenu';

export default memo(RecipeMenu);
